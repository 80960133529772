<template>
  <v-container
    id="industries-list"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="8"
        md="8"
      >
        <base-material-card color="info">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Available
            </div>
          </template>
          <v-card
            class="mx-auto"
            outlined
          >
            <invite-code-list
              :key="listKey"
              @editItem="editItem"
            />
          </v-card>
        </base-material-card>
      </v-col>

      <v-col
        cols="4"
        md="4"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Add New
            </div>

            <div class="text-text-subtitle-1 font-weight-light">
              Add new Invite Code
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="inviteCodeObj.invite_code"
                    class="purple-input"
                    label="Invite Code"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                >
                  <v-checkbox
                    v-model="inviteCodeObj.multi_use"
                    label="Multi use"
                  />
                </v-col>

                <v-col
                  v-if="!inviteCodeObj.multi_use"
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="inviteCodeObj.first_name"
                    class="purple-input"
                    label="First Name"
                  />
                </v-col>

                <v-col
                  v-if="!inviteCodeObj.multi_use"
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="inviteCodeObj.last_name"
                    class="purple-input"
                    label="Last Name"
                  />
                </v-col>

                <v-col
                  v-if="!inviteCodeObj.multi_use"
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="inviteCodeObj.email_name"
                    class="purple-input"
                    label="Email Address"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                >
                  <v-checkbox
                    v-model="inviteCodeObj.active"
                    label="Active"
                  />
                </v-col>

                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    class="mr-0"
                    @click="isEdit ? updateInviteCodeAction() : createInviteCodeAction()"
                  >
                    {{ isEdit ? 'Update' : 'Create' }} Invite Code
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-alert
                    v-if="alert.show"
                    border="left"
                    dense
                    text
                    :type="alert.type"
                  >
                    {{ alert.text }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { createInviteCode, updateInviteCode } from '@/services/admin'
  import inviteCodeList from '@/components/inviteCodeList'
  export default {
    components: { inviteCodeList },
    data () {
      return {
        inviteCodeObj: {

        },
        alert: {
          show: false,
          text: '',
          type: 'success',
        },
        isEdit: false,
        itemId: null,
        listKey: 'abc',
      }
    },
    computed: {
    },
    methods: {
      createInviteCodeAction () {
        if (!this.inviteCodeObj.invite_code) {
          this.alert.show = true
          this.alert.text = 'Please fill all required fields.'
          this.alert.type = 'error'
          return
        }
        createInviteCode(this.inviteCodeObj).then(res => {
          this.alert.show = true
          this.alert.text = 'Invite Code Created.'
          this.alert.type = 'success'
        })
      },
      updateInviteCodeAction () {
        if (!this.inviteCodeObj.invite_code) {
          this.alert.show = true
          this.alert.text = 'Please fill all required fields.'
          this.alert.type = 'error'
          this.inviteCodeObj = {}
          return
        }
        updateInviteCode(this.itemId, this.inviteCodeObj).then(res => {
          this.alert.show = true
          this.alert.text = 'Invite Code Updated.'
          this.alert.type = 'success'
          this.isEdit = false
          this.inviteCodeObj = {}
          this.listKey = new Date()
        })
      },
      editItem (items) {
        const data = JSON.parse(items)
        this.itemId = data.id
        delete data.id
        delete data.createdAt
        this.inviteCodeObj = data
        this.isEdit = true
        this.alert.show = false
      },
      getReadableDate (date) {
        const d = new Date(date)
        return d.toUTCString()
      },
    },
  }
</script>
